<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="table"
          title="검사대상 목록"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="data.inspTgtModelList"
          :filtering="false"
          :isExcelDown="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable"
          rowKey="hazardousMachineryInspectionResultTargetId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn
                v-if="editable && data.inspTgtModelList.length > 0"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="data.inspTgtModelList"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="save"
                @btnCallback="saveCallback" 
              />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="table2"
          title="관리항목 목록"
          :columns="grid3.columns"
          :gridHeight="grid3.height"
          :data="data.rsltMngModelList"
          :filtering="false"
          selection="multiple"
          :isExcelDown="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable"
          rowKey="hazardousMachineryInspectionResultMngId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="추가" :showLoading="false"  icon="add" @btnClicked="add3" />
              <c-btn v-if="editable && data.rsltMngModelList.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="remove3" />
              <c-btn
                v-if="editable && data.rsltMngModelList.length > 0"
                :url="saveUrl3"
                :isSubmit="isSave3"
                :param="data.rsltMngModelList"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="save3"
                @btnCallback="saveCallback" 
              />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12">
        <c-table
          ref="table3"
          title="필수항목 목록"
          :columns="grid2.columns"
          :gridHeight="grid2.height"
          :data="data.rsltItemModelList"
          :filtering="false"
          :isExcelDown="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable"
          rowKey="hazardousMachineryInspectionResultItemId"
        >
          <template v-slot:suffixTitle>
            <font style="font-size:0.8em;font-weight:300;">
              &nbsp;&nbsp;
              V : 육안검사 &nbsp;&nbsp;
              E : 장비검사 &nbsp;&nbsp;
              A : 작동검사 &nbsp;&nbsp;
              C : 인증확인대상 &nbsp;&nbsp;
            </font>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn
                v-if="editable && data.rsltItemModelList.length > 0"
                :url="saveUrl2"
                :isSubmit="isSave2"
                :param="data.rsltItemModelList"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="save2"
                @btnCallback="saveCallback" 
              />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props }">
          <template v-if="props.row.testMothod">
            <q-chip
              v-for="(name, index) in $_.split(props.row.testMothodName, ',')"
              :key="index"
              outline square 
              color="orange"
              text-color="white"
              class="q-ma-none"
              >
              {{name + ' '}}
            </q-chip>
          </template>
        </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from "@/js/selectConfig";
import transactionConfig from "@/js/transactionConfig";
export default {
  name: "hazard-equipment-history-result",
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        hhmHazardousMachineryId: '',
        inspectionNo: '',
        machineryClassification: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      data: {
        inspTgtModelList: [],
        rsltItemModelList: [],
        rsltMngModelList: [],
      },
      disabled: false,
        
      grid: {
        columns: [
          {
            name: "itemName",
            field: "itemName",
            label: "검사항목",
            style: 'width:350px',
            align: "center",
            sortable: false,
          },
          {
            required: true,
            setHeader: true,
            name: "inspectionFlag",
            field: "inspectionFlag",
            label: "점검결과",
            style: 'width:100px',
            align: "center",
            type: 'select',
            sortable: false,
            comboItems: [
              { code: 'Y', codeName: '실시' },
              { code: 'N', codeName: '비적용' },
            ]
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '조항',
            align: 'center',
            style: 'width:60px',
            sortable: false,
          },
          // {
          //   name: "itemName2",
          //   field: "itemName2",
          //   label: "검사항목",
          //   style: 'width:35%',
          //   align: "center",
          //   sortable: false,
          // },
          // {
          //   required: true,
          //   setHeader: true,
          //   name: "inspectionFlag2",
          //   field: "inspectionFlag2",
          //   label: "점검결과",
          //   style: 'width:15%',
          //   align: "center",
          //   type: 'select',
          //   sortable: false,
          //   comboItems: [
          //     { code: 'Y', codeName: '실시' },
          //     { code: 'N', codeName: '비적용' },
          //   ]
          // },
        ],
        data: [],
        height: '500px'
      },
      grid2: {
        columns: [
          {
            name: "itemName",
            field: "itemName",
            label: "검사항목",
            style: 'width:150px',
            align: "center",
            sortable: false,
          },
          {
            name: "itemCriterion",
            field: "itemCriterion",
            label: "판정기준",
            align: "left",
            sortable: false,
          },
          {
            required: true,
            setHeader: true,
            name: "inspectionFlag",
            field: "inspectionFlag",
            label: "점검결과",
            style: 'width:150px',
            align: "center",
            type: 'select',
            sortable: false,
            comboItems: [
              { code: 'Y', codeName: '실시' },
              { code: 'N', codeName: '비적용' },
            ]
          },
          {
            name: 'testMothod',
            field: 'testMothod',
            label: '검사방법',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'useEquip',
            field: 'useEquip',
            label: '활용장비',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'article',
            field: 'article',
            label: '조항',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
        height: '700px'
      },
      grid3: {
        columns: [
          {
            required: true,
            name: "itemName",
            field: "itemName",
            label: "검사항목",
            style: 'width:150px',
            align: "left",
            type: "text",
            sortable: false,
          },
          {
            required: true,
            name: "inspectionResult",
            field: "inspectionResult",
            label: "검사결과(안전검사고시 미달내용 기재)",
            align: "left",
            type: "text",
            sortable: false,
          },
          {
            name: "article",
            field: "article",
            label: "근거조항",
            style: 'width:100px',
            align: "left",
            type: "text",
            sortable: false,
          },
          {
            name: "sortOrder",
            field: "sortOrder",
            label: "순서",
            style: 'width:70px',
            align: "right",
            type: "number",
            sortable: false,
          },
        ],
        data: [],
        height: '500px'
      },
      deleteUrl: "",
      updateUrl: "",
      updateMode: false,
      getUrl: "",
      saveUrl: '',
      saveUrl2: '',
      saveUrl3: '',
      deleteUrl3: '',
      saveType: 'POST',
      isSave: false,
      isSave2: false,
      isSave3: false,
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
      this.init();
  },
  beforeDestroy() {},
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.getUrl = selectConfig.sop.hhm.hazard.hazardHistory.result.get.url;
      this.saveUrl = transactionConfig.sop.hhm.hazard.hazardHistory.result.save.url;
      this.saveUrl2 = transactionConfig.sop.hhm.hazard.hazardHistory.result.save2.url;
      this.saveUrl3 = transactionConfig.sop.hhm.hazard.hazardHistory.result.save3.url;
      this.deleteUrl3 = transactionConfig.sop.hhm.hazard.hazardHistory.result.delete3.url;

      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.inspectionNo) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.inspectionNo, this.popupParam.machineryClassification);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = this.$_.clone(_result.data);
        },);
      } 
    },
    save() {
      if (this.$comm.validTable(this.grid.columns, this.data.inspTgtModelList)) {
        window.getApp.$emit("CONFIRM", {
          title: "확인",
          message: "저장하시겠습니까?",
          // TODO : 필요시 추가하세요.
          type: "info", // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.data.inspTgtModelList, _item => {
              _item.inspectionNo = this.popupParam.inspectionNo;
              _item.regUserId = this.$store.getters.user.userId;
              _item.chgUserId = this.$store.getters.user.userId;
            })
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {},
        });
      }
    },
    save2() {
      if (this.$comm.validTable(this.grid2.columns, this.data.rsltItemModelList)) {
        window.getApp.$emit("CONFIRM", {
          title: "확인",
          message: "저장하시겠습니까?",
          // TODO : 필요시 추가하세요.
          type: "info", // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.data.rsltItemModelList, _item => {
              _item.inspectionNo = this.popupParam.inspectionNo;
              _item.regUserId = this.$store.getters.user.userId;
              _item.chgUserId = this.$store.getters.user.userId;
            })
            this.isSave2 = !this.isSave2;
          },
          // 취소 callback 함수
          cancelCallback: () => {},
        });
      }
    },
    save3() {
      if (this.$comm.validTable(this.grid3.columns, this.data.rsltMngModelList)) {
        window.getApp.$emit("CONFIRM", {
          title: "확인",
          message: "저장하시겠습니까?",
          // TODO : 필요시 추가하세요.
          type: "info", // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.data.rsltMngModelList, _item => {
              _item.regUserId = this.$store.getters.user.userId;
              _item.chgUserId = this.$store.getters.user.userId;
            })
            this.isSave3 = !this.isSave3;
          },
          // 취소 callback 함수
          cancelCallback: () => {},
        });
      }
    },
    saveCallback() {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.getDetail();
    },
    add3() {
      this.data.rsltMngModelList.splice(0, 0, {
        inspectionNo: this.popupParam.inspectionNo,
        hazardousMachineryInspectionResultMngId: uid(),
        sortOrder: this.data.rsltMngModelList.length + 1,
        itemName: '',
        inspectionResult: '',
        article: '',
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    remove3() {
      let selectData = this.$refs['table2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit("CONFIRM", {
          title: "확인",
          message: "삭제하시겠습니까?",
          // TODO : 필요시 추가하세요.
          type: "warning", // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl3
            this.$http.type = "DELETE";
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.data.rsltMngModelList = this.$_.reject(this.data.rsltMngModelList,
              { hazardousMachineryInspectionResultMngId: item.hazardousMachineryInspectionResultMngId })
            })
              window.getApp.$emit("APP_REQUEST_SUCCESS");
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {},
        });
      }
    },
  },
};
</script>
